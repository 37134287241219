import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Grid, Container, Button } from '@mui/material'
import Typist from 'react-typist'
import HomeCarousel from '../components/HomeCarousel'

function Home() {
    const divTransparencyBackgroundStyles = {
        background: 'linear-gradient(0deg, transparent, rgba(0,0,0,.7), transparent)',
        padding: {
            xs: '1.5rem .5rem',
            sm: '1.5rem'
        },
        paddingBottom: '2.5rem',
        borderRadius: '1rem'
    }

    return (
        <Box sx={{
            marginTop: {
                xs: '1.5rem',
                md: '2rem'
            },
            height: '100%',
            width: '100%',
            backgroundImage: 'url("./images/chicagoskyline_hero.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: {
                xs: '1100px auto',
                md: 'cover'
            },
            backgroundPositionX: 'center'
        }}>
            <Container maxWidth="false">
                <Grid container
                    justifyContent='center'
                    alignItems='center'
                    direction='row'
                >
                    <Grid item xs={12} md={6}
                        sx={divTransparencyBackgroundStyles}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                width: '100%',
                                color: theme => theme.palette.text.secondary,
                                fontWeight: 600
                            }}
                        >
                            <Typist 
                                cursor={{hideWhenDone: true, element: '_', blink: true}}
                                startDelay={300}
                                avgTypingDelay={90}
                            >
                            <span>Learn to Code</span>
                            </Typist>
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                mt: '2rem',
                                fontSize: {
                                    xs: '1.2rem',
                                    md: '1.4rem'
                                },
                                fontWeight: 'bold'
                            }}>
                            We help bridge the technology education gap that exists in black and latino neighborhoods by engaging students in our communities through introductory coding labs.
                        </Typography>
                        <Typography sx={{ mt: '1.5rem'}}>
                            <b>COST:</b> FREE + laptops are provided <br/>
                            <b> WHO:</b> 9-12th grade students<br/>
                            <b>WHEN:</b> Thursday nights starting October 13th from 7-8:30pm for 6 weeks<br />
                            <b>WHERE:</b> The Cure Church @ 2715 W Madison St (East Garfield Park) <br />
                        </Typography>
                        <Button variant="contained" color="primary" href="/register"
                            sx={{
                                mt: '1.5rem'
                            }}
                        >Register Now</Button>
                    </Grid>
                    <Grid item xs={12} md={6} textAlign="center" sx={{minHeight: '170px'}}>
                    <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                        <iframe
                            src="https://player.vimeo.com/video/721612206?h=6df8bfcb9e&badge=0&autopause=0&player_id=0&app_id=58479"
                            frameBorder={0}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen=""
                            style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%"
                            }}
                            title="Creation Coding Lab"
                        />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sx={divTransparencyBackgroundStyles}>
                        <Typography variant="h3" sx={{
                            mb: '1rem',
                            fontWeight: 'bold'
                        }}>
                            Opportunities are Waiting
                        </Typography>
                        <Typography variant="body1">
                        80% of future jobs will require technical skills. The engagement of U.S. children in STEM education is the key to companies filling an estimated 9 million jobs in the industry by 2022.
                        </Typography>
                        <Typography variant="body1" marginTop="1rem">
                        Students will gain awareness, confidence, and inspiration through their participation in our labs. They will be introduced to coding, artificial intelligence, and other computing concepts. This opportunity levels the playing field and gives our students the same opportunities that are afforded to their peers in affluent neighborhoods.
                        </Typography>
                    </Grid>
                    <Grid item sx={divTransparencyBackgroundStyles}>
                        <Typography variant="h3" sx={{
                            mb: '1rem'
                        }}>
                            About Us
                        </Typography>
                        <Typography variant="body1">
                            Creation Coding Lab is an organization that hosts self-led technology labs
                            for students in grades 9-12 to expose them to coding and other advanced technology concepts. 
                            Our goal is to connect students into further educational opportunities after our programs.
                        </Typography>
                        <Typography variant="body1" marginTop="1rem">
                            The mission is to empower underrepresented groups in tech and provide an outlet for students to explore their creativity in STEM.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Home;