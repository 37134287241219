import * as React from 'react';
import "@fontsource/noto-sans-mono";
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/Layout';
import Home from './pages/Home';
import Register from './pages/Register';

const defaultFontFamily = 'noto sans mono'
const mintGreenColor = '#17bd80';

let theme = createTheme({
  typography: {
    fontFamily: defaultFontFamily,
    h1: {
      fontSize: '4rem',
      color: mintGreenColor
    },
    h2: {
      fontSize: '2rem',
      color: '#fff'
    },
    h3: {
      color: mintGreenColor
    }
  },
  palette: {
    mode: 'dark',
    text: {
      secondary: mintGreenColor
    },
    primary: {
      main: mintGreenColor
    }
  }
})
theme = responsiveFontSizes(theme)
// theme = createTheme(theme, {
//   typography: {
//     fontFamily: defaultFontFamily,

//   },
//   palette: {
//     mode: 'dark',
//     text: {
//       secondary: '#17bd80'
//     }
//   }
// });

export default function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/register" element={<Register/>} />
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}