import { Typography, Container, Stack } from '@mui/material'
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";

const pageContainer = {
    marginTop: '1rem'
}

const commonInputStyles = {
    marginBottom: '1.25rem',
    width: {
        xs: '95%',
        sm: '400px'
    } 
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const inputHeadingStyles = {
    marginBottom: '1rem'
}

export default function Register() {
    const navigate = useNavigate();
    const [values, setValues] = useState()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { 
        setOpen(false);
        navigate("/", { replace: true });
    }

    const onSubmit = (data) => {
        setValues(data)

        let xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://kp6jdgbbz4.execute-api.us-east-2.amazonaws.com/production/register')

        // 3. Send the request over the network
        xhr.send(JSON.stringify(data));

        // 4. This will be called after the response is received
        xhr.onload = function() {
            if (xhr.status != 200) { // analyze HTTP status of the response
                alert("Request failed - please call or email to register."); // e.g. 404: Not Found
            } else { // show the result
                handleOpen();
            }
        };

        xhr.onerror = function() {
            alert("Request failed - please call or email to register.");
        };
    }

    return (
        <main>
            <Container sx={pageContainer}>
                <Typography variant="h1" sx={{textAlign: 'center'}}>Registration</Typography>
            </Container>
            <Container sx={{ py: 8, mx: { xs: 1, md: 4 }}} >
                <FormContainer onSuccess={onSubmit}>
                    <Stack direction={'column'} >
                        <Typography variant="p" sx={inputHeadingStyles}>Registrant Details</Typography>
                        <TextFieldElement sx={commonInputStyles} type="text" name={'studentFirstName'} label={'First name'} required />
                        <TextFieldElement sx={commonInputStyles} type="text" name={'studentLastName'} label={'Last name'}  required /> 
                        <TextFieldElement sx={commonInputStyles} type="text" name={'studentAddress'} label={'Address'}  required multiline rows={3}/> 
                        <TextFieldElement sx={commonInputStyles} type="tel" name={'studentPhone'} label={'Phone number'} required 
                            validation={{
                                validate: (value) => {
                                    var pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
                                    return pattern.test(value) || 'Enter a valid phone number.'
                                }
                            }} />
                        <TextFieldElement sx={commonInputStyles} type="email" name={'studentEmail'} label={'Email'} required />
                        <TextFieldElement sx={commonInputStyles} type="number" name={'studentGradeNumber'} label={'Grade Number (9-12)'} required 
                            validation={{
                                validate: (value) => {
                                    var pattern = /^9|10|11|12$/;
                                    return pattern.test(value) || 'Enter a grade number between 9 & 12.'
                                }
                            }} />
                        <Typography variant="p" sx={inputHeadingStyles}>Alternate Contact</Typography>
                        <TextFieldElement sx={commonInputStyles} type="text" name={'alternateFirstName'} label={'First name'} required />
                        <TextFieldElement sx={commonInputStyles} type="text" name={'alternateLastName'} label={'Last name'}  required />
                        <TextFieldElement sx={commonInputStyles} type="text" name={'alternateRelation'} label={'What is your relation to this person?'} required />
                        <TextFieldElement sx={commonInputStyles} type="tel" name={'alternatePhone'} label={'Phone number'} required
                            validation={{
                                validate: (value) => {
                                    var pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
                                    return pattern.test(value) || 'Enter a valid phone number.'
                                }
                            }} />
                        <TextFieldElement sx={commonInputStyles} type="email" name={'alternateEmail'} label={'Email'} required />
                        <Typography variant="p" sx={inputHeadingStyles}>--</Typography>
                        <TextFieldElement sx={commonInputStyles} type="text" name={'howDidYouHearAboutUs'} label={'How did you hear about us?'} required/>
                        <Button type={'submit'} variant={'contained'} color={'primary'} sx={{width: '150px'}}>Submit</Button>
                    </Stack>
                </FormContainer>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                            Registration Successful
                            </Typography>
                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Thank you for registering. We will be in contact with you soon regarding your registration.
                            </Typography>
                            <Button sx={{ mt: 2 }} onClick={() => handleClose()}>Close</Button>
                        </Box>
                    </Fade>
                </Modal>
            </Container>
        </main>
        
    )
}