// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import AppBar from '@mui/material/AppBar';

// export default function Appbar() {
//     return(
//         <AppBar
//                 position="static"
//                 color="default"
//                 elevation={0}
//             >
//                 <Toolbar sx={{ flexWrap: 'wrap' }}>
//                     <img src="./citylogo.svg" width="25px" height="25px" style={{ marginRight: '15px' }} />
//                     <Typography variant="h6" color="white" noWrap sx={{ flexGrow: 1 }}>
//                         CityCodingLab
//                     </Typography>
//                     <nav />
//                 </Toolbar>
//             </AppBar>
//     );
// };

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

const pages = [
    // { name: 'Labs', href: '/labs' }
];

const Appbar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" color="default" elevation={0}>
            <Container maxWidth="false" sx={(theme) => ({
                pl: {
                    md: 6
                }
            })}>
                <Toolbar disableGutters>
                    <Box sx={{ mr: 2 }}>
                        {/* <img src="./citylogo.svg" width="25px" height="25px" style={{ marginRight: '15px' }} /> */}
                        <Link href="/" sx={{ textDecoration: 'none' }}>
                            <img src="./images/CCL-Logo.dark.png" alt="Creation Coding Lab icon" style={{ height: '75px' }} />
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default Appbar;