import React from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Appbar from './Appbar'
import Footerbar from './Footerbar'
import GlobalStyles from '@mui/material/GlobalStyles';

function Layout({ children }) {
    return (
        <div className="App">
            <GlobalStyles styles={{
                ul: { margin: 0, padding: 0, listStyle: 'none' }
            }} />
            <CssBaseline />
            <Appbar style={{marginBottom: '1rem'}}/>
            {/* main content */}
            <main>{children}</main>
            <Footerbar />
        </div>
    )
}

export default Layout;