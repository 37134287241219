import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const Footerbar = () => {

    return (
        <footer>
            <Grid container alignItems="center" sx={{
                    px: {
                        sm: '3rem',
                        xs: '1.5rem'
                    },
                    pt: {
                        xs: '1rem',
                        sm: '0'
                    }
                }}>
                <Grid item xs={12} sm={11}>
                    <Box>
                        <Typography variant="span" sx={{color: theme => theme.palette.text.secondary}}>Contact Us:</Typography>
                        <Typography variant="span"> <Link color="inherit" href="mailto:creationcodinglab@gmail.com" target="_blank" rel="noopener">creationcodinglab@gmail.com</Link> / <Link color="inherit" href="tel:7086225920" target="_blank" rel="noopener">(708) 622-5920</Link></Typography>
                        
                    </Box>
                </Grid>
                <Grid item xs={12} sm={1}>
                    <Box sx={{ textAlign: {
                            sm: 'right',
                            xs: 'center'
                        } 
                    }}><img src="./images/CCL-Logo3.dark.png" alt="Creation Coding Lab footer icon" style={{ height: '75px' }} /></Box>
                </Grid>
            </Grid>
        </footer>
    );
};
export default Footerbar;